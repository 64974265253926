<template>
  <div class="box processManage">
    <!-- <navi-gation /> -->
    <div v-if="flagCreate">
      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-input v-model.trim="queryObj.templateName" size="small" :placeholder="$t('ReportManagement.CreateReport.Placeholder')" @keyup.enter.native="searchList"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="searchList">{{$t('public.Inquire')}}</el-button>
        </el-col>
      </el-row>
      <el-row style="padding: 0px 10px 10px 10px">
        <el-col :span="24">
          <el-button-group>
            <el-button type="primary" size="small" @click="CreateFrom">{{$t('public.New')}}</el-button>
            <el-button style="margin-left: 0.5px" :disabled="!hasSeleted" :type="typeInfo" size="small" @click="deletes">{{$t('public.Delete')}}</el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="4" style="padding: 0 10px">
        <el-table ref="multipleTable" :data="dictData" stripe size="mini" :row-key="getRowKeys" class="sort_table" tooltip-effect="dark" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#f7f8fa' }" :default-sort="{ prop: 'updateTime', order: 'descending' }" style="width: 100%" @sort-change="sort">
          <el-table-column align="center" type="selection" width="60" :reserve-selection="true">
          </el-table-column>
          <el-table-column width="180" header-align="left" align="left" prop="templateName" :label="$t('ReportManagement.CreateReport.ReportName')" show-overflow-tooltip>
          </el-table-column>
          <el-table-column width="180" header-align="left" align="left" prop="templateType" :label="$t('ReportManagement.CreateReport.ReportType')" show-overflow-tooltip>
            <template v-slot="{ row }">
              <template v-if="row.templateType == 1"> {{$t('ReportManagement.reportType1')}} </template>
              <template v-if="row.templateType == 2"> {{$t('ReportManagement.reportType2')}} </template>
              <template v-if="row.templateType == 3"> {{$t('ReportManagement.reportType3')}} </template>
              <template v-if="row.templateType == 4"> {{$t('ReportManagement.reportType4')}} </template>
            </template>
          </el-table-column>
          <el-table-column width="180" prop="templateCircle" :label="$t('ReportManagement.CreateReport.Cycle')" header-align="left" align="left" show-overflow-tooltip>
            <template v-slot="{ row }">
              <template v-if="row.templateCircle == 1"> {{$t('ReportManagement.CreateReport.Moon')}} </template>
              <template v-if="row.templateCircle == 2"> {{$t('ReportManagement.CreateReport.Single')}} </template>
            </template>
          </el-table-column>
          <el-table-column width="180" prop="updateTime" :label="$t('public.ModificationTime')" header-align="left" align="left" show-overflow-tooltip>
            <template v-slot="{ row }">{{
              row.updateTime | dateformat
            }}</template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')" header-align="left" align="left">
            <template v-slot="{ row }">
              <span class="action_icon">
              <img @click="editOperation(row)" src="../../assets/icon_write.png" :title="$t('public.Editor')" alt="" style="width: 15px; height: 15px" />
              </span>
              <span class="action_icon">
              <img @click="deletes(row)" src="@/images/icon_del.png" :title="$t('public.Delete')" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <pagination :page="queryObj.currentPage" :limit="queryObj.pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        <ul class="tableCheckBox" style="" v-show="isCheckShow" @mouseleave="leave">
          <li @click="curSelection()">当前页全选</li>
          <li @click="toggleSelection()">取消全选</li>
        </ul>
      </el-row>
    </div>
    <div v-else style="padding: 20px 120px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item :label="$t('ReportManagement.CreateReport.ReportName')+'：'" prop="templateName">
          <el-input size="small" style="width: 455px" :placeholder="$t('ReportManagement.CreateReport.Placeholder')" v-model="ruleForm.templateName">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('ReportManagement.CreateReport.ReportType')+'：'" prop="templateType">
          <el-select style="width: 455px" size="small" v-model="ruleForm.templateType" @change="changeEvent" placeholder="请选择报表类型" :disabled="flagSelect">
            <el-option :label="$t('ReportManagement.reportType1')" value="1"></el-option>
            <el-option :label="$t('ReportManagement.reportType2')" value="2"></el-option>
            <el-option :label="$t('ReportManagement.reportType3')" value="3"></el-option>
            <el-option :label="$t('ReportManagement.reportType4')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('ReportManagement.CreateReport.Cycle')+'：'" prop="templateCircle">
          <el-radio-group v-model="ruleForm.templateCircle" @change="changeRadio">
            <el-radio label="1">{{$t('ReportManagement.CreateReport.Moon')}}</el-radio>
            <el-radio label="2">{{$t('ReportManagement.CreateReport.Single')}}</el-radio>
          </el-radio-group>
          <span v-if="checkedFlag" style="padding: 0 10px">
            <el-date-picker :clearable="false" size="small" style="width: 155px" v-model="ruleForm.startTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始日期" :disabled="flagTime" :picker-options="pickerOptions0">
            </el-date-picker>
            -
            <el-date-picker :clearable="false" size="small" style="width: 155px" v-model="ruleForm.endTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束日期" :disabled="flagTime" :picker-options="pickerOptions1">
            </el-date-picker>
          </span>
        </el-form-item>
        <el-form-item :label="$t('ReportManagement.CreateReport.ReportContent')+'：'" prop="templateContent" v-if="flagOperating">
          <el-card class="box-card">
            <el-checkbox-group v-model="ruleForm.templateContent">
              <span v-for="(item, index) in concent" :key="index">
                <el-checkbox style="width: 120px" :disabled="item.disabled" :label="item.label" name="type">{{ $t(`ReportManagement.CreateReport.${item.label}`) }}
                  <img v-if="item.desc" style="width: 15px" src="@/images/wen1.png" alt="" :title="item.desc" />
                </el-checkbox>
              </span>
            </el-checkbox-group>
          </el-card>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">{{$t('public.Save')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
import naviGation from '@/components/hearder/index'
import pagination from '@/components/pagination/page.vue'
export default {
  components: { pagination, naviGation, dialogInfo },
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 3600 * 1000 * 24
        }
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 3600 * 1000 * 24
        }
      },
      pickerOptions3: {
        disabledDate: (time) => {
          let endDateVal = this.ruleForm.endTime
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime()
          }
        }
      },
      pickerOptions4: {
        disabledDate: (time) => {
          let beginDateVal = this.ruleForm.startTime
          if (beginDateVal) {
            return time.getTime() < new Date(beginDateVal).getTime()
          }
        }
      },
      total: 0,
      concent: [],
      dictData: [],
      titleInfo: '',
      typeInfo: 'info',
      resource: false, //周期显示
      flagTime: false, //时间格式禁用状态
      flagCreate: true, //新增显示影藏
      flagSelect: false,
      isCheckShow: false,
      flagOperating: true, //报表内容
      visibaelFlag: false,
      timeDefaultShow: '',
      multipleSelection: [],
      rules: {
        templateName: [
          { required: true, message: '请输入报表名称', trigger: 'blur' },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: '报表名称不支持特殊字符',
            trigger: 'blur'
          }
        ],
        templateType: [
          { required: true, message: '请选择报表类型', trigger: 'change' }
        ],
        date1: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        date2: [
          {
            type: 'date',
            required: true,
            message: '请选择时间',
            trigger: 'change'
          }
        ],
        templateContent: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个活动性质',
            trigger: 'change'
          }
        ],
        templateCircle: [
          { required: true, message: '请选择周期', trigger: 'change' }
        ]
      },
      ruleForm: {
        templateName: '',
        templateType: '1',
        startTime: '',
        endTime: '',
        templateContent: [
          'deviceName',
          'userName',
          'groupFullName',
          'osVersion'
        ],
        templateCircle: '1'
      },
      queryObj: {
        currentPage: 1,
        pageSize: 10,
        orderColume: 'update_time',
        orderRule: 'DESC',
        templateName: ''
      },
      dataConcent: {
        equipment: [
          {
            label: 'deviceName',
            value: '设备名称',
            disabled: true
          },
          {
            label: 'userName',
            value: '用户名',
            disabled: true
          },
          {
            label: 'groupFullName',
            value: '部门',
            disabled: true
          },
          {
            label: 'osVersion',
            value: '操作系统',
            disabled: true
          },

          {
            label: 'violationCount',
            value: '违规行为数',
            disabled: false
          },
          {
            label: 'dangerCount',
            value: '威胁行为数',
            disabled: false
          },
          {
            label: 'sensitiveCount',
            value: '敏感行为数',
            disabled: false
          },
          {
            label: 'eventCount',
            value: '安全事件数',
            disabled: false
          },
          {
            label: 'sdkCount',
            value: 'SDK行为数',
            disabled: false
          }
        ],
        application: [
          {
            label: 'appName',
            value: '应用名称',
            disabled: true
          },
          {
            label: 'appVersion',
            value: '应用版本',
            disabled: true
          },
          {
            label: 'osVersion',
            value: '操作系统',
            disabled: true
          },
          {
            label: 'dangerCount',
            value: '威胁行为数',
            disabled: false
          },
          {
            label: 'sensitiveCount',
            value: '敏感行为数',
            disabled: false
          },
          {
            label: 'eventCount',
            value: '安全事件数',
            disabled: false
          },
          {
            label: 'sdkCount',
            value: 'SDK行为数',
            disabled: false
          }
        ],
        threat: [
          {
            label: 'type',
            value: '类型',
            disabled: true,
            desc: this.$t('ReportManagement.CreateReport.DescMessage1')
          },
          {
            label: 'dangerType',
            value: '威胁类型',
            disabled: true,
            desc: this.$t('ReportManagement.CreateReport.DescMessage2')
          },
          {
            label: 'deviceName',
            value: '设备名称',
            disabled: true
          },
          {
            label: 'userName',
            value: '用户名',
            disabled: true
          },
          {
            label: 'groupFullName',
            value: '部门',
            disabled: true
          },
          {
            label: 'appName',
            value: '应用名称',
            disabled: true
          },
          {
            label: 'appVersion',
            value: '应用版本',
            disabled: true
          },
          {
            label: 'osVersion',
            value: '操作系统',
            disabled: true
          },
          {
            label: 'level',
            value: '行为级别',
            disabled: false
          },
          {
            label: 'district',
            value: '位置',
            disabled: false
          },
          {
            label: 'netType',
            value: '上网方式',
            disabled: false
          },
          {
            label: 'outIp',
            value: '外网IP',
            disabled: false
          },
          {
            label: 'ip',
            value: '内网IP',
            disabled: false
          },
          {
            label: 'wifiSsid',
            value: 'WIFI-SSID',
            disabled: false
          },
          {
            label: 'wifiMac',
            value: 'WIFI-MAC',
            disabled: false
          },
          {
            label: 'time',
            value: '产生时间',
            disabled: false,
            desc: this.$t('ReportManagement.CreateReport.DescMessage3')
          },
          {
            label: 'createTime',
            value: '上报时间',
            disabled: false,
            desc: this.$t('ReportManagement.CreateReport.DescMessage4')
          }
        ]
      },
      dataFrom: {}
    }
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length
    },
    checkedFlag() {
      return this.ruleForm.templateCircle == 2 ? true : false
    }
  },
  created() {
    this.concent = this.dataConcent.equipment
    this.getdatatime()
    this.tableList()
    this.changeRadio()
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    sort(column) {//排序
      if (column.order === 'ascending') {
        this.queryObj.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.queryObj.orderRule = 'DESC'
      }
      if (column.prop == 'updateTime') {
        this.queryObj.orderColume = 'update_time'
      } else if (column.prop == 'templateCircle') {
        this.queryObj.orderColume = 'template_circle'
      } else if (column.prop == 'templateType') {
        this.queryObj.orderColume = 'template_type'
      } else if (column.prop == 'templateName') {
        this.queryObj.orderColume = 'template_name'
      }
      this.tableList()
    },
    async tableList() {
      const res = await this.$axios.post(
        '/httpServe/reportTemplate/getDataInfo',
        this.queryObj,
        true
      )
      this.dictData = res.data.content
      this.total = res.data.total
    },
    async deleteBaths(val) {//删除
      if (val.id) {
        let data = []
        data.push(val.id)
        let param = {
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/reportTemplate/delete',
          param,
          true
        )
        if (res.code == 200) {
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
          this.searchList()
        }
      } else {
        let data = []
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id)
        })
        let param = {
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/reportTemplate/delete',
          param,
          true
        )
        if (res.code == 200) {
          if (this.multipleSelection.length > 1) {
            this.$message({
              message: this.$t('public.SuccessfullyDeleteds'),
              type: 'success',
              offset: 100
            })
          } else {
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
          }
          this.searchList()
          this.$refs.multipleTable.clearSelection();
        }
      }
    },
    async submitSave() {// 提交
      if (this.ruleForm.id) {
        let param = {
          ids: [this.dataFrom.id],
          templateType: this.ruleForm.templateType * 1,
          templateCircle: this.ruleForm.templateCircle * 1,
          templateName: this.ruleForm.templateName,
          templateContent: this.ruleForm.templateContent,
          startTime: this.ruleForm.startTime,
          endTime: this.ruleForm.endTime
        }
        const res = await this.$axios.post(
          '/httpServe/reportTemplate/update',
          param,
          true
        )
        if (res.data == -1) {
          this.flagCreate = false
          this.$message({
            message: this.$t('ReportManagement.Error4'),
            type: 'error',
            offset: 100
          })
        }
        if (res.code === 101) {
          this.$message({
            message: this.$t('ReportManagement.Error5'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data === 1) {
          this.queryObj.templateName = ""
          this.$message({
            message: this.$t('ReportManagement.CheckDel3'),
            type: 'success',
            offset: 100
          })
          this.tableList()
          this.flagCreate = true
          this.ruleForm = {
            templateType: '1',
            templateContent: [
              'deviceName',
              'userName',
              'groupFullName',
              'osVersion'
            ],
            templateCircle: '1'
          }
          this.concent = this.dataConcent.equipment
        }
      } else {
        const res = await this.$axios.post(
          '/httpServe/reportTemplate/save',
          this.ruleForm,
          true
        )
        if (res.code === 101) {
          this.$message({
            message: this.$t('ReportManagement.Error7'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data == 1) {
          this.queryObj.templateName = ""
          this.$message({
            message: this.$t('ReportManagement.CheckDel4'),
            type: 'success',
            offset: 100
          })
          this.tableList()
          this.flagCreate = true
          this.ruleForm = {
            templateType: '1',
            templateContent: [
              'deviceName',
              'userName',
              'groupFullName',
              'osVersion'
            ],
            templateCircle: '1'
          }
          this.concent = this.dataConcent.equipment
        }
        if (res.data == -1) {
          this.$message({
          message: this.$t('ReportManagement.Error4'),
          type: 'error',
          offset: 100
        })
        return false
        }
      }
    },
    dataTime() {
      this.timeDefaultShow = new Date()
      this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
    },
    getdatamonth() {//默认显示上个月
      let data = new Date()
      return data.getTime() - 3600 * 1000 * 24 * 30
    },
    getdatatime() {//默认显示今天
      return new Date().getTime()
    },
    getCurrentMonthFirst() {
      var date = new Date()
      date.setDate(1)
      var month = parseInt(date.getMonth() + 1)
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month - 1
      }
      if (day < 10) {
        day = '0' + day
      }
      this.ruleForm.startTime = date.getFullYear() + '-' + month + '-' + day
    },
    getCurrentMonthLast() {
      var date = new Date()
      var currentMonth = date.getMonth()
      var nextMonth = ++currentMonth
      var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
      var oneDay = 1000 * 60 * 60 * 24
      var lastTime = new Date(nextMonthFirstDay - oneDay)
      var month = parseInt(lastTime.getMonth() + 1)
      var day = lastTime.getDate()
      if (month < 10) {
        month = '0' + month - 1
      }
      if (day < 10) {
        day = '0' + day
      }
      this.ruleForm.endTime = date.getFullYear() + '-' + month + '-' + day
    },
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : '0' + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
      var res =
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minutes +
        ':' +
        seconds
      return res
    },
    searchList() {
      this.queryObj.currentPage = 1
      this.tableList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (this.multipleSelection.length > 0) {
        this.typeInfo = 'primary'
      } else {
        this.typeInfo = 'info'
      }
    },
    handleParentGetList(page, limit) {// 响应分页组件查询事件
      this.queryObj.currentPage = page
      this.queryObj.pageSize = limit
      this.tableList()// 调用查询方法
    },
    changeEvent(val) {//多选报表类型区分事件
      this.ruleForm.templateContent = []
      if (val == 1) {
        this.timeVeao()
        this.flagTime = false
        this.flagOperating = true
        this.concent = this.dataConcent.equipment
      } else if (val == 2) {
        this.timeVeao()
        this.flagTime = false
        this.flagOperating = true
        this.concent = this.dataConcent.application
      } else if (val == 3) {
        this.timeVeao()
        this.flagTime = false
        this.flagOperating = true
        this.concent = this.dataConcent.threat
      } else if (val == 4) {
        this.getCurrentMonthFirst()
        this.getCurrentMonthLast()
        this.concent = []
        this.flagTime = true
        this.flagOperating = false
      }
      this.concent.forEach((item) => {
        if (item.disabled) {
          this.ruleForm.templateContent.push(item.label)
        }
      })
    },
    timeVeao() {//时间日期为空
      this.ruleForm.endTime = ''
      this.ruleForm.startTime = ''
    },
    changeRadio(val) {// 单选
      if (val == 1) {
        this.resource = false
      } else {
        if (this.ruleForm.templateType == 4) {
          this.getCurrentMonthFirst()
          this.getCurrentMonthLast()
        }
        this.resource = true
      }
    },
    maskLayer() {//全选遮罩层
      this.isCheckShow = true
    },
    leave() {// 鼠标移出弹框
      this.isCheckShow = false
    },
    curSelection() {//当前页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    toggleAllSelection() {//所有页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    toggleSelection(rows) {//取消全选
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },
    CreateFrom() {// 新增
      this.flagCreate = false
      this.flagSelect = false
    },
    editOperation(val) {//编辑
      this.flagSelect = true
      this.changeEvent(val.templateType)
      this.dataFrom = val
      let data = val.templateContent ? val.templateContent.split(',') : null
      this.ruleForm = {
        id: val.id,
        templateType: val.templateType + '',
        templateCircle: val.templateCircle + '',
        templateName: val.templateName,
        templateContent: data,
        startTime: val.startTime,
        endTime: val.endTime
      }
      this.flagCreate = false
    },
    submitForm(formName) {// 确认
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let endtime = this.ruleForm.endTime
          let startime = this.ruleForm.startTime
          if (this.ruleForm.templateCircle == '2' && !this.ruleForm.startTime) {
            this.$message({
              message: this.$t('ReportManagement.Error1'),
              type: 'error',
              offset: 100
            })
            return false
          }
          if (this.ruleForm.startTime && !this.ruleForm.endTime) {
            this.$message({
              message: this.$t('ReportManagement.Error2'),
              type: 'error',
              offset: 100
            })
            return false
          }
          if (new Date(startime).getTime() > new Date(endtime).getTime()) {
            this.$message({
              message: this.$t('ReportManagement.Error3'),
              type: 'error',
              offset: 100
            })
            return false
          }
          if (
            this.ruleForm.templateType == 1 &&
            this.ruleForm.templateContent.length > 4
          ) {
            this.submitSave()
          } else if (
            this.ruleForm.templateType == 2 &&
            this.ruleForm.templateContent.length > 3
          ) {
            this.submitSave()
          } else if (
            this.ruleForm.templateType == 3 &&
            this.ruleForm.templateContent.length > 8
          ) {
            this.submitSave()
          } else if (this.ruleForm.templateType == 4) {
            this.submitSave()
          } else {
            this.$message({
              message: this.$t('ReportManagement.Error6'),
              type: 'error',
              offset: 100
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deletes(val) {//删除
      this.$confirm(this.$t('ReportManagement.CheckDel5'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        type: 'warning',
        customClass: 'persdsd'
      })
        .then(() => {
          this.deleteBaths(val)
        })
        .catch(() => {
          return false
        })
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {//确定回调事件
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px;
}
.el-table {
  position: relative;
}
.tableCheckBox {//全选弹框
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    list-style: none !important;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
.box-card {
  width: 450px;
}
.el-table th.gutter {
  display: table-cell !important;
}
.el-form-item {
  margin-top: 17px;
}
</style>
